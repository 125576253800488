import React from "react";
import PageNavBar from "../../components/NavBar/PageNavBar";
import "./TermAndCondition.scss";

const TermAndCondition = () => {
  return (
    <>
      <PageNavBar name="Term & Condition" />
      <div className="about-us">
        <p className="heading">Term & Condition</p>
        <p className="content">
          Welcome to B competition By using our platform, you agree to the
          following terms and conditions. Please read them carefully before
          proceeding.
        </p>
        <p className="sub-heading">1. Acceptance of Terms</p>
        <p className="content">
          By accessing or using B Competition, you confirm that you have read,
          understood, and agreed to these Terms and Conditions, as well as our
          Privacy Policy. If you do not agree, you may not use our services.
        </p>

        <p className="sub-heading">2. User Accounts</p>
        <p className="content">
          To access certain features of the platform, you may be required to
          create an account. You are responsible for maintaining the
          confidentiality of your account credentials and for all activities
          conducted under your account.
        </p>

        <p className="sub-heading">3. Test Rules and Conduct</p>
        <p className="content">
          By participating in tests, you agree to follow all instructions and
          refrain from any dishonest or unfair practices. We reserve the right
          to disqualify users who engage in cheating, plagiarism, or other
          violations of fair use policies.
        </p>

        <p className="sub-heading">4. Intellectual Property</p>
        <p className="content">
          All content on the platform, including text, graphics, logos,
          trademarks, and software, is the property of B Competition or its
          licensors. You may not reproduce, distribute, or create derivative
          works from this content without explicit permission.
        </p>

        <p className="sub-heading">5. Fees and Payments</p>
        <p className="content">
          Some features of the platform may require payment. By completing a
          transaction, you agree to provide accurate billing information and
          authorize us to charge the applicable fees.
        </p>

        <p className="sub-heading">6. Limitation of Liability</p>
        <p className="content">
          B Competition will not be liable for any damages, including but not
          limited to direct, indirect, incidental, or consequential damages,
          arising from your use of the platform or inability to access certain
          features.
        </p>

        <p className="sub-heading">7. Modifications to Terms</p>
        <p className="content">
          We reserve the right to modify these Terms and Conditions at any time.
          Changes will be effective immediately upon posting. Continued use of
          the platform indicates acceptance of the updated terms.
        </p>

        <p className="sub-heading">8. Termination</p>
        <p className="content">
          We may suspend or terminate your access to the platform if we
          determine that you have violated these Terms and Conditions or engaged
          in unlawful activities.
        </p>
      </div>
    </>
  );
};

export default TermAndCondition;
