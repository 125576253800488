import React, { useEffect, useState } from "react";
import PageNavBar from "../../../components/NavBar/PageNavBar";
import AppLogo from "../../../assets/app_logo.jpg";
import "./referEarn.scss";
import { GoShareAndroid } from "react-icons/go";
import { useSelector } from "react-redux";
import { api } from "../../..";
import { PiHandCoinsBold } from "react-icons/pi";
import Loader from "../../../components/Loader/Loader";

const ReferAndEarn = () => {
  const [tabs, setTabs] = useState("myrewards");
  const [loading, setLoading] = useState(false);
  const { user } = useSelector((state) => state.user);
  const [myRewards, setMyRewards] = useState();
  const [myreferrals, setMyReferrals] = useState();

  useEffect(() => {
    fetchMyReward();
    fetchMyReferrals();
  }, []);

  const fetchMyReward = async () => {
    try {
      setLoading(true);
      const formData = new FormData();
      formData.append("auth_token", user?.data[0]?.auth_token);
      formData.append("auth_id", user?.data[0]?.auth_id);
      const { data } = await api.post("/test_api/student/myreferal", formData);
      if (data) {
        setMyRewards(data);
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchMyReferrals = async () => {
    try {
      setLoading(true);
      const formData = new FormData();
      formData.append("auth_token", user?.data[0]?.auth_token);
      formData.append("auth_id", user?.data[0]?.auth_id);
      const { data } = await api.post(
        "/test_api/Student/refer_student_amount",
        formData
      );
      if (data) {
        setMyReferrals(data);
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const shareHandler = () => {
    const message = encodeURIComponent(
      `📌Don't Miss Out! Simply click on this link to grab this offer:\n https://play.google.com/store/apps/details?id=com.jbccNew \n \n https://bcompt.vercel.app/ \n ${user?.data[0]?.name} 💰Use The Referral Code: ${user?.data[0]?.referral_code} \n Let's read, Learn, Earn, win, and Succeed Together!💪🏻📚🤑🏆`
    );
    const shareUrl = `https://wa.me/?text=${message}`;
    window.open(shareUrl, "_blank");
  };

  const switcherFun = (data) => {
    setTabs(data);
  };
  return (
    <div>
      {loading && <Loader />}
      <PageNavBar name="Refer & Earn" />
      <div className="total-earn-cont">
        <div>
          <p>Total Rewards Earned</p>
          <p className="bold-txt">
            ₹{" "}
            {myRewards?.totalIncomeamount === "0.00"
              ? "0"
              : myRewards?.totalIncomeamount}{" "}
            <PiHandCoinsBold />{" "}
          </p>
        </div>
        <div>
          <img src={AppLogo} alt={AppLogo} />
          <p className="bold-txt refer">
            Refer Code: {user?.data && user?.data[0]?.referral_code}
          </p>
        </div>
      </div>
      <div className="result-tabs-switch-cont">
        <div onClick={() => switcherFun("myrewards")}>
          <p className={tabs === "myrewards" ? "active" : ""}>My Rewards</p>
          <div className={`bar ${tabs === "myrewards" ? "baractive" : ""}`} />
        </div>
        <hr />
        <div onClick={() => switcherFun("myreferrals")}>
          <p className={tabs === "myreferrals" ? "active" : ""}>My Referrals</p>
          <div className={`bar ${tabs === "myreferrals" ? "baractive" : ""}`} />
        </div>
      </div>

      {tabs === "myrewards" ? (
        <>
          <div className="refer-detail header">
            <div>Member</div>
            <hr />
            <div>Course Active</div>
            <hr />
            <div>Test Active</div>
          </div>
          <div className="refer-detail">
            <div>{myRewards?.data?.memberlevel1}</div>
            <hr />
            <div>{myRewards?.data?.courselevel1}</div>
            <hr />
            <div>{myRewards?.data?.testactivelevel1}</div>
          </div>
          <div className="reffer-offer">
            <p className="txt-color">Refer and Earn</p>
            <p className="fadded-txt">
              Refer and Earn up to 1000+ Coins and Get Assured Coins on Each
              Test and Course Successful Enrollment.{" "}
            </p>
            <p className="bold-txt" onClick={shareHandler}>
              {user?.data && user?.data[0]?.referral_code}{" "}
              <GoShareAndroid size={25} />
            </p>
          </div>
        </>
      ) : myreferrals?.data ? (
        myreferrals?.data?.map((item, index) => (
          <div key={index} className="myRefferalsCard">
            <p>{index + 1}</p>
            <p>{item?.name}</p>
            <p>{item?.mobile_number.toString().slice(0, 7)}XXX</p>
          </div>
        ))
      ) : (
        <p
          style={{
            textAlign: "center",
            marginTop: "30px",
            color: "red",
            fontSize: "20px",
            fontWeight: "600",
          }}
        >
          No data Avaliable
        </p>
      )}
    </div>
  );
};

export default ReferAndEarn;
