import React from "react";
import "./About.scss";
import PageNavBar from "../../components/NavBar/PageNavBar";

const AboutUs = () => {
  return (
    <>
      <PageNavBar name="About" />
      <div className="about-us">
        <p className="heading">About us</p>
        <h5 className="content">
          Welcome to B competition , your ultimate destination for learning and
          self-assessment! Our mission is to provide an engaging and efficient
          platform for users to test their knowledge across a wide range of
          subjects, helping them excel in academics, career advancement, or
          personal growth.
        </h5>
        <p className="heading">Who We Are</p>
        <h2 className="content">
          We are a passionate team of educators, developers, and innovators
          dedicated to creating a platform that bridges the gap between learning
          and evaluation. Our app is designed to make the testing process
          seamless, enjoyable, and accessible to everyone, whether you're a
          student preparing for exams, a professional brushing up on skills, or
          a lifelong learner exploring new topics.
        </h2>
        <p className="heading">Our Vision</p>
        <h2 className="content">
          We envision a future where learning is dynamic, engaging, and
          rewarding. B competition aims to empower individuals by giving them
          the tools to measure and improve their knowledge effectively. Join us
          on this journey of growth and discovery. Let's make learning not just
          a necessity but an exciting adventure!
        </h2>
      </div>
    </>
  );
};

export default AboutUs;
