import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { api } from "..";

const initialState = {
  user: {},
};

//register user
export const registerUser = createAsyncThunk(
  "user/registerUser", // Changed action type here
  async ({
    mobileNo,
    state_id,
    city_id,
    pin_code,
    name,
    email,
    date_of_birth,
    gender,
    address,
    country_id,
    school_name,
    refer_code,
    deviceId,
  }) => {
    const formData = new FormData();
    const fcmToken = localStorage.getItem("fcmToken");
    formData.append("mobile_number", mobileNo);
    formData.append("fcm_id", fcmToken);
    formData.append("device_id", deviceId);
    formData.append("state_id", state_id);
    formData.append("city_id", city_id);
    formData.append("pin_code", pin_code);
    formData.append("name", name);
    formData.append("email", email);
    formData.append("date_of_birth", date_of_birth);
    formData.append("gender", gender);
    formData.append("address", address);
    formData.append("country_id", country_id);
    formData.append("school_name", school_name);
    formData.append("refer_code", refer_code || "");
    const response = await api.post("/test_api/Api/register", formData);
    return response.data;
  }
);

//login user
export const loginUser = createAsyncThunk(
  "user/loginUser", // Changed action type here
  async ({ mobileNo, deviceId }) => {
    const formData = new FormData();
    const fcmToken = localStorage.getItem("fcmToken");
    formData.append("mobile_number", mobileNo);
    formData.append("device_id", deviceId);
    formData.append("fcm_id", fcmToken);
    const response = await api.post(`/test_api/Api/login`, formData);
    return response.data;
  }
);

//logout user
export const logoutUser = createAsyncThunk("user/logoutUser", async () => {
  return;
});

//verify OTP
export const verifyOtp = createAsyncThunk("user/verifyOtp", async () => {
  return true;
});

//fetching user
export const fetchUser = createAsyncThunk("user/fetchUser", async () => {
  const user = await JSON.parse(localStorage.getItem("user"));
  return user || {};
});

//Slice definition for user state
export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
      localStorage.setItem("user", JSON.stringify(state.user));
    },
  },
  extraReducers: (builder) => {
    builder

      //login user
      .addCase(loginUser.pending, (state) => {
        state.status = "loading";
      })
      .addCase(loginUser.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.user = action.payload;
      })
      .addCase(loginUser.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })

      //register user
      .addCase(registerUser.pending, (state) => {
        state.status = "loading";
      })
      .addCase(registerUser.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.user = action.payload;
        state.user.message === "Register Success" &&
          localStorage.setItem("user", JSON.stringify(state.user));
      })
      .addCase(registerUser.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })

      //logout user
      .addCase(logoutUser.pending, (state) => {
        state.status = "loading";
      })
      .addCase(logoutUser.fulfilled, (state) => {
        state.status = "succeeded";
        state.user = initialState;
      })
      .addCase(logoutUser.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })

      //fetch user
      .addCase(fetchUser.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchUser.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.user = action.payload;
      })
      .addCase(fetchUser.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })

      // OTP verification cases
      .addCase(verifyOtp.pending, (state) => {
        state.otpStatus = "loading";
      })
      .addCase(verifyOtp.fulfilled, (state) => {
        state.otpStatus = "succeeded";
        state.user = { ...state.user, isVerified: true }; // Mark user as verified
        state.user.is_register &&
          localStorage.setItem("user", JSON.stringify(state.user));
      })
      .addCase(verifyOtp.rejected, (state, action) => {
        state.otpStatus = "failed";
        state.error = action.error.message;
      });
  },
});

export const { setUser } = userSlice.actions;

export default userSlice.reducer;
