import React from "react";
import PageNavBar from "../../components/NavBar/PageNavBar";

const Privacy = () => {
  return (
    <>
      <PageNavBar name="Privacy & Policy" />
      <div className="about-us">
        <p className="heading">Privacy & Policy</p>
        <p className="content">
          B competition we values your privacy. This Privacy Policy explains how
          we collect, use, disclose, and protect your information when you use
          our application. By using our Service, you agree to the practices
          described in this policy.
        </p>
        <p className="heading">Data Privacy</p>
        <p className="content">
          We prioritize the security of your personal and payment information.
          All transactions are processed securely via Razorpay, and we do not
          store sensitive payment details. Please refer to our Privacy Policy
          for more details.
        </p>
        <p className="heading">Information We Collect</p>
        <p className="content">
          We collect information that identifies, relates to, describes, or is
          capable of being associated with you. This includes:
        </p>
        <p className="sub-heading">Information You Provide Directly:</p>
        <p className="sub-content">
          Name, email address, password, and other information when you
          register.
        </p>
        <p className="sub-content">
          Your answers, scores, and other data related to your participation in
          tests.
        </p>
        <p className="heading">Data Security</p>
        <p className="content">
          We use appropriate technical and organizational measures to protect
          your information. However, no online platform is entirely secure, and
          we cannot guarantee absolute security.
        </p>
      </div>
    </>
  );
};

export default Privacy;
