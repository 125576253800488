import { configureStore } from "@reduxjs/toolkit";
import userReducer from "./slices/userSlice";
import drawerReducer from "./slices/drawerSlice";
import bannerImagesReducer from "./slices/bannerImageSlice";
import notificationReducer from "./slices/notificationSlice";
import questionReducer from "./slices/testQuestions";
import tests from './slices/testList';


const store = configureStore({
  reducer: {
    user: userReducer,
    customDrawer: drawerReducer,
    bannerImages: bannerImagesReducer,
    tests: tests,
    notification: notificationReducer,
    testQuestions: questionReducer,
  },
});

export default store;
