import React from 'react'
import PageNavBar from '../../../components/NavBar/PageNavBar'

const Language = () => {
  return (
    <div>
      <PageNavBar name="Language" />
    </div>
   )
}

export default Language
