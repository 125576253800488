import React, { useEffect, useState } from "react";
import AppLogo from "../../assets/app_logo.jpg";
import "./NavBar.scss";
import { CiSearch } from "react-icons/ci";
import { MdSupportAgent } from "react-icons/md";
import { GoBell } from "react-icons/go";
import SideBar from "./SideBar";
import MiddleNavBar from "./MiddleNavBar";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { testSlice } from "../../slices/testList";

const NavBar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchOpen, setSearchOpen] = useState(false);
  const [inputSearch, setInputSearch] = useState("");
  const [navTest, setNavTest] = useState();
  const [orgTest, setOrgTest] = useState(null);
  const { tests } = useSelector((state) => state.tests);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!orgTest && tests && tests.length > 0) {
      setOrgTest(tests); // Set original tests only once when data is available
    }
    setNavTest(tests);
  }, [tests]);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  const searchHandler = async (e) => {
    const query = e.target.value.toLowerCase();
    setInputSearch(query);

    if (navTest && Array.isArray(navTest)) {
      if (query === "") {
        console.log(tests);
        dispatch(testSlice.actions.setvalue(orgTest));
      } else {
        // Perform filtering
        const filteredResults = navTest.filter((item) =>
          item.test_title.toLowerCase().includes(query)
        );
        dispatch(testSlice.actions.setvalue(filteredResults));
      }
    }
  };
  return (
    <>
      <div className="NavBar">
        <div>
          <img src={AppLogo} alt="AppLogo" onClick={toggleSidebar} />
          {!searchOpen && <p>B competition</p>}
          <SideBar isOpen={isOpen} toggleSidebar={toggleSidebar} />
        </div>
        {searchOpen && (
          <div className="search-cont">
            <input
              type="text"
              className="search-inp"
              value={inputSearch}
              onChange={(e) => searchHandler(e)}
            />
          </div>
        )}
        <div>
          <div>
            <CiSearch size={25} onClick={() => setSearchOpen(!searchOpen)} />
            <MdSupportAgent size={25} onClick={() => navigate("/support")} />
            <GoBell size={25} onClick={() => navigate("/notifications")} />
          </div>
        </div>
      </div>
      <MiddleNavBar />
    </>
  );
};

export default NavBar;
