import React from "react";
import { FaMedal } from "react-icons/fa";
import { MdOutlineCalendarMonth } from "react-icons/md";
import { IoMdClock } from "react-icons/io";
import { CiUser } from "react-icons/ci";
import { TbCoinRupee } from "react-icons/tb";
import "./testcard.scss";
import { useNavigate } from "react-router-dom";

const TestCard = ({ id, item }) => {
  const navigate = useNavigate();
  return (
    <div
      className="test-card"
      onClick={() => navigate(`/test-detail/${id}`, { state: { item: item } })}
    >
      <div>
        <FaMedal />
      </div>
      <div>
        <div>
          <div>
            <p>{item?.test_title}</p>
            <p className="time">Exam Date: {item?.publish_at}</p>
          </div>
          {item?.is_subscription && (
            <div>
              <p className="purchased">Purchased</p>
            </div>
          )}
        </div>
        <hr />
        <div>
          <div>
            <div>
              <MdOutlineCalendarMonth />
              <p>Date</p>
            </div>
            <div>{item?.publish_at}</div>
          </div>
          <div>
            <div>
              <IoMdClock />
              <p>Time</p>
            </div>
            <div>{item?.publish_time}</div>
          </div>
          <div>
            <div>
              <CiUser />
              <p>Slots</p>
            </div>
            <div>{item?.number_of_slot}</div>
          </div>
          <div>
            <div>
              <TbCoinRupee />
              <p>Prizepool</p>
            </div>
            <div>₹ {item?.prize_pool_amount}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TestCard;
