import axios from "axios";
import React, { useEffect, useState } from "react";

const TimerComponent = ({ item, testIsRun, setTestIsRun }) => {
  // Calculate remaining time
  const [remainingTime, setRemainingTime] = useState("");
  const [curTime, setCurTime] = useState();

  useEffect(() => {
    getAccurateTime();
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurTime((prev) => (prev += 1000));
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  async function getAccurateTime() {
    try {
      setCurTime(Date.now());
      return;
    } catch (error) {
      setCurTime(Date.now());
      console.error("Error fetching accurate time:", error);
    }
  }

  useEffect(() => {
    const updateRemainingTime = async () => {
      // const data = 54654564564654;
      // console.log("2",curTime);
      // setCurTime(curTime - 1)

      const exactDate = new Date(`${item?.publish_at} ${item?.publish_time}`);
      const exactTimeInMilliseconds = exactDate.getTime();
      const difference = exactTimeInMilliseconds - curTime;
      if (difference <= 0) {
        setRemainingTime("00:00:00:00");
        setTestIsRun(true);
        return;
      }

      const days = Math.floor(difference / (24 * 60 * 60 * 1000));
      const hours = Math.floor(
        (difference % (24 * 60 * 60 * 1000)) / (60 * 60 * 1000)
      );
      const minutes = Math.floor((difference % (60 * 60 * 1000)) / (60 * 1000));
      const seconds = Math.floor((difference % (60 * 1000)) / 1000);

      setRemainingTime(
        `${days.toString().padStart(2, "0")}:${hours
          .toString()
          .padStart(2, "0")}:${minutes.toString().padStart(2, "0")}:${seconds
          .toString()
          .padStart(2, "0")}`
      );
    };

    updateRemainingTime(); // Initial call
  }, [item, setTestIsRun, curTime]);

  return (
    <div>
      {!testIsRun && <p className="timing">Test Starting in {remainingTime}</p>}
      {testIsRun && <p className="timing">Test in Progress</p>}
    </div>
  );
};

export default TimerComponent;
