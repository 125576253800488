import React, { useEffect, useState } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import "./carousel.scss";
import { useSelector } from "react-redux";
import InsideLoader from "../InsideLoader/InsideLoader";

const ImageCarousel = () => {
  const bannerImages = useSelector((state) => state.bannerImages);
  const [bannerImage, setBannerImage] = useState([]);

  useEffect(() => {
    setBannerImage(
      bannerImages?.images?.data?.filter(
        (item) => item !== undefined && item !== null
      ) || []
    );
  }, [bannerImages?.images]);

  return (
    <div className="imagecarousel">
      {
        bannerImages?.status === "loading" && <InsideLoader />
      }
      <Carousel
        autoPlay={true}
        infiniteLoop={true}
        showThumbs={false}
        showStatus={false}
        showIndicators={true}
        showArrows={true}
      >
        {bannerImage?.map((item, index) => (
          <div key={index}>
            <img
              src={bannerImages?.images?.image_base_url + item?.banner}
              className="carousel-image"
              alt={`Banner ${index + 1}`}
            />
          </div>
        ))}
      </Carousel>
    </div>
  );
};

export default ImageCarousel;
