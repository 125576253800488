import React, { useEffect, useState } from "react";
import PageNavBar from "../../components/NavBar/PageNavBar";
import MiddleNavBar from "../../components/NavBar/MiddleNavBar";
import { toast } from "react-toastify";
const Class = () => {
  const [purClass, setPurClass] = useState(false);
  const notify = () => {
    if (purClass) {
      toast.success("course loaded Successfully!", {});
    } else {
      toast.error("You have not purchase any course", {});
    }
  };

  useEffect(() => {
    notify();
  }, [purClass]);
  return (
    <div>
      <PageNavBar name="Class" />
      <MiddleNavBar />
      <p
        style={{
          textAlign: "center",
          fontWeight: "700",
          color: "red",
          marginTop: "30px",
        }}
      >
        No Class found.
      </p>
    </div>
  );
};

export default Class;
