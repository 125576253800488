import React, { useEffect, useState } from "react";
import "./PersonalDetails.scss";
import AppLogo from "../../../assets/app_logo.jpeg";
import { toast } from "react-toastify";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { registerUser } from "../../../slices/userSlice";
import Loader from "../../../components/Loader/Loader";
import { useLocation, useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { api } from "../../..";
const PersonalDetails = () => {
  //react hooks
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const deviceId = uuidv4();
  const { mobileNumber } = location.state || {};

  //user states
  const [name, setName] = useState("");
  const [phoneNo, setPhoneNo] = useState(mobileNumber);
  const [email, setEmail] = useState("");
  const [schoolName, setSchoolName] = useState("");
  const [dob, setDob] = useState("");
  const [gender, setGender] = useState("");
  const [address, setAddress] = useState("");
  const [pinCode, setPinCode] = useState("");
  const [referCode, setReferCode] = useState("");
  const [term, setTerm] = useState(false);
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState({
    id: "",
    country: "",
  });
  const [selectedState, setSelectedState] = useState({
    id: "",
    state: "",
  });
  const [selectedCity, setSelectedCity] = useState({
    id: "",
    city: "",
  });
  const [inputType, setInputType] = useState("text");

  useEffect(() => {
    // Fetch all countries when the component loads
    fetchCountriesData();
  }, []);

  const fetchCountriesData = async () => {
    const { data } = await api.post("/test_api/Api/country_list");

    if (data) {
      setCountries(data && data?.data);
    }
  };

  //country handler
  const handleCountryChange = async (event) => {
    const selectedCountryData = JSON.parse(event.target.value);
    setSelectedCountry(selectedCountryData);

    const formData = new FormData();
    formData.append("country_id", selectedCountryData.id); // Use selectedCountryData directly

    try {
      const { data } = await api.post("/test_api/Api/state_list", formData);

      if (data) {
        setStates(data?.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  //state handler
  const handleStateChange = async (event) => {
    const selectedStateData = JSON.parse(event.target.value);
    setSelectedState(selectedStateData);

    const formData = new FormData();
    formData.append("state_id", selectedStateData.id); // Use selectedCountryData directly

    try {
      const { data } = await api.post("/test_api/Api/city_list", formData);

      if (data) {
        setCities(data?.data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  //city handler
  const handleCityChange = async (event) => {
    const selectedCityData = JSON.parse(event.target.value);
    setSelectedCity(selectedCityData);
  };

  const registerHandler = async () => {
    if (
      !phoneNo ||
      !name ||
      !email ||
      !schoolName ||
      !dob ||
      !gender ||
      !address ||
      !pinCode ||
      !term
    ) {
      toast.warning("Please provide all details");
      return;
    }

    try {
      setLoading(true);
      const response = await dispatch(
        registerUser({
          mobileNo: phoneNo,
          state_id: selectedState.id,
          city_id: selectedCity.id,
          pin_code: pinCode,
          name: name,
          email: email,
          date_of_birth: dob,
          gender: gender,
          address: address,
          country_id: selectedCountry.id,
          school_name: schoolName,
          refer_code: referCode,
          deviceId: deviceId
        })
      ).unwrap();

      if (response) {
        if (response?.status === 1) {
          navigate("/");
          return;
        }
        toast.warning("Mobile no is already exists");
      }

      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };
  return (
    <div className="container">
      {loading ? (
        <Loader />
      ) : (
        <div className="logincard">
          <div className="applogo">
            <img src={AppLogo} alt="AppLogo" />
          </div>
          <div className="basic-info">
            <p className="title">Basic Info</p>
            <div className="details">
              <input
                type="tel"
                placeholder="Please enter your phone number"
                maxLength={10}
                minLength={10}
                value={phoneNo}
                readOnly
              />

              <input
                type="text"
                placeholder="Please enter your name"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />

              <input
                type="email"
                placeholder="Please enter your email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <input
                type="text"
                placeholder="Please enter your school/college name"
                value={schoolName}
                onChange={(e) => setSchoolName(e.target.value)}
              />
              <input
                type={inputType}
                placeholder="dd-mm-yy"
                onFocus={() => setInputType("date")}
                onBlur={() => setInputType("text")}
                value={dob}
                onChange={(e) => setDob(e.target.value)}
              />
              <div className="gender-cont">
                <p>Please select your gender</p>
                <input
                  type="radio"
                  name="gender"
                  id="male"
                  value="Male"
                  onChange={(e) => setGender(e.target.value)}
                />
                <label htmlFor="male">Male</label>
                <input
                  type="radio"
                  name="gender"
                  id="female"
                  value="Female"
                  onChange={(e) => setGender(e.target.value)}
                />
                <label htmlFor="female">female</label>
              </div>
            </div>
          </div>

          <div className="basic-info">
            <p className="title">Personal Info</p>
            <div className="details">
              <input
                type="text"
                placeholder="Please enter your address"
                value={address}
                onChange={(e) => setAddress(e.target.value)}
              />

              <input
                type="tel"
                placeholder="Please enter your pincode"
                maxLength={6}
                value={pinCode}
                onChange={(e) => setPinCode(e.target.value)}
              />

              <select
                value={JSON.stringify(selectedCountry.country)}
                onChange={(e) => handleCountryChange(e)}
              >
                <option value="">Select your Country</option>
                {countries &&
                  countries.map((country, index) => (
                    <option
                      key={index}
                      value={JSON.stringify({
                        id: country.id,
                        country_name: country.country_name,
                      })}
                    >
                      {country.country_name}
                    </option>
                  ))}
              </select>
              <select
                value={JSON.stringify(selectedState.state)}
                onChange={(e) => handleStateChange(e)}
              >
                <option value="">Select your State</option>
                {states &&
                  states.map((state, index) => (
                    <option
                      key={index}
                      value={JSON.stringify({
                        id: state.id,
                        country_name: state.state_name,
                      })}
                    >
                      {state.state_name}
                    </option>
                  ))}
              </select>

              <select
                value={JSON.stringify(selectedCity.city)}
                onChange={(e) => handleCityChange(e)}
              >
                <option value="">Select your District</option>
                {cities &&
                  cities.map((city, index) => (
                    <option
                      key={index}
                      value={JSON.stringify({
                        id: city.id,
                        country_name: city.city_name,
                      })}
                    >
                      {city.city_name}
                    </option>
                  ))}
              </select>
              <input
                type="text"
                placeholder="Refer code"
                value={referCode}
                onChange={(e) => setReferCode(e.target.value)}
              />
              <div className="terms-cont">
                <input
                  type="checkbox"
                  id="term&condition"
                  checked={term}
                  onChange={(e) => setTerm(!term)}
                />
                <label htmlFor="term&condition">
                  By continuing, you agree our terms & condition
                </label>
              </div>

              <button className="btn-register" onClick={registerHandler}>
                Register
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PersonalDetails;
