import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { api } from "..";

const initialState = {
  drawer: [],
  drawerValue: {},
};

//fecth category from apis
export const drawerCategory = createAsyncThunk(
  "drawer/drawerCategory",
  async () => {
    const response = await api.post("/test_api/Api/category_list");

    return response?.data?.data;
  }
);

export const drawerSlice = createSlice({
  name: "drawer",
  initialState,
  reducers: {
    setvalue: (state, action) => {
      state.drawerValue = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder

      //fetch drawer
      .addCase(drawerCategory.pending, (state) => {
        state.status = "loading";
      })
      .addCase(drawerCategory.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.drawer = action.payload;
        state.drawerValue = action.payload[0]; 
      })
      .addCase(drawerCategory.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export default drawerSlice.reducer;
