import React from "react";
import PageNavBar from "../../components/NavBar/PageNavBar";

const Contact = () => {
  return (
    <>
      <PageNavBar name="Contact Us" />
      <div className="about-us">
        <p className="heading">Contact Us</p>
        <p className="content">
          We value your feedback, questions, and concerns. Feel free to reach
          out to us using the following contact details:
        </p>
        <p className="heading">Support Email</p>
        <p className="content">
          For any assistance, queries, or issues related to our app, you can
          contact our support team at:
        </p>
        <p className="content">
          Email: <span> helpbcompetition@gmail.com</span>
        </p>
        <p className="content">
          Our support team will respond to your inquiries promptly, typically
          within 24-48 hours.
        </p>
        <p className="heading">Office Address:</p>
        <p className="content">
          You are welcome to visit our office or send us mail at:
        </p>
        <p className="sub-heading">Address:</p>
        <p className="sub-content">
          Kisan Market, Ground Floor, Patasang, Rahui, Nalanda, Bihar, 803119
        </p>
        <p className="sub-heading">Business Hours</p>
        <p className="sub-content">
          Our office is open during the following hours:
        </p>
        <p className="sub-content">
          Monday to Saturday: <span>10:00 AM - 6:00 PM</span>
        </p>
        <p className="sub-content">
          Sunday and Public Holidays: <span>Closed</span>
        </p>
      </div>
    </>
  );
};

export default Contact;
