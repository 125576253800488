import React from 'react'
import PageNavBar from '../../components/NavBar/PageNavBar'

const PaymentPolicy = () => {
  return (
    <>
     <PageNavBar name="Payment Policy" /> 
     <div className="about-us">
        <p className="heading">Payments and Refund Policy</p>
        <p className="content">
          We use Razorpay as our secure payment gateway for all transactions. By
          making a payment on our platform, you agree to the following terms:
        </p>
         <p className="sub-heading">Payment Confirmation:</p>
        <p className="sub-content">
          After completing a transaction, you will receive an email confirmation
          for the payment.
        </p>
        <p className="sub-heading">Refund Policy:</p>
        <p className="sub-content">
          Refunds will only be processed in cases of technical issues or billing
          errors.
        </p>
        <p className="sub-content">
          To request a refund, you must email us at{" "}
          <span>helpbcompetition@gmail.com</span> within 3 days of the
          transaction date.
        </p>
        <p className="sub-content">
          Refund requests will be reviewed, and a resolution will be provided
          within 7 working days of receiving your request.
        </p>
        <p className="sub-content">
          Approved refunds will be processed back to the original payment method
          via Razorpay.
        </p>
     </div>
    </>
  )
}

export default PaymentPolicy
