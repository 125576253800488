import React, { useEffect, useState } from "react";
import PageNavBar from "../../components/NavBar/PageNavBar";
import MiddleNavBar from "../../components/NavBar/MiddleNavBar";
import "./Myexam.scss";
import TestCard from "../../components/TestCard/TestCard";
import axios from "axios";
import { useSelector } from "react-redux";
import InsideLoader from "../../components/InsideLoader/InsideLoader";
import { api } from "../..";

const MyExams = () => {
  const { user } = useSelector((state) => state?.user);
  const { drawerValue } = useSelector((state) => state?.customDrawer);
  const [tabs, setTabs] = useState("upcoming");
  const [loading, setLoading] = useState(false);
  const [testData, setTestData] = useState([]);

  const switcherFun = (data) => {
    setTabs(data);
    fetchExamData(data);
  };

  useEffect(() => {
    fetchExamData("upcoming");
  }, []);

  const fetchExamData = async (data) => {
    try {
      setLoading(true);
      const formData = new FormData();
      formData.append("category_id", drawerValue?.value || 1);
      formData.append("auth_token", user?.data[0]?.auth_token);
      formData.append("auth_id", user?.data[0]?.auth_id);
      const response = await api.post(
        `/test_api/Test/student_${data}_test`,
        formData
      );
      if (response) {
        setTestData(response?.data && response?.data?.data);
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };
  return (
    <>
      <PageNavBar name="My Exams" />
      <MiddleNavBar />
      <div className="my-exam-cont">
        <div className="my-exam-switch-cont ">
          <div onClick={() => switcherFun("upcoming")}>
            <p className={tabs === "upcoming" ? "active" : ""}>Upcoming</p>
            <div className={`bar ${tabs === "upcoming" ? "baractive" : ""}`} />
          </div>
          <hr />
          <div onClick={() => switcherFun("ongoing")}>
            <p className={tabs === "ongoing" ? "active" : ""}>Ongoing</p>
            <div className={`bar ${tabs === "ongoing" ? "baractive" : ""}`} />
          </div>
          <hr />
          <div onClick={() => switcherFun("completed")}>
            <p className={tabs === "completed" ? "active" : ""}>Completed</p>
            <div className={`bar ${tabs === "completed" ? "baractive" : ""}`} />
          </div>
          <hr />
        </div>

        {tabs === "upcoming" ? (
          <div className="test-cont">
            {loading && <InsideLoader />}
            {!loading && testData && testData.length > 0 ? (
              testData.map((item, index) => (
                <TestCard id={item?.test_id} item={item} key={index} />
              ))
            ) : (
              <p
                style={{
                  textAlign: "center",
                  fontWeight: "700",
                  color: "red",
                  marginTop: "30px",
                }}
              >
                No upcoming exams found.
              </p>
            )}
          </div>
        ) : (
          ""
        )}
        {tabs === "ongoing" ? (
          <div className="test-cont">
            {loading && <InsideLoader />}
            {!loading && testData && testData.length > 0 ? (
              testData.map((item, index) => (
                <TestCard id={item?.test_id} item={item} key={index} />
              ))
            ) : (
              <p
                style={{
                  textAlign: "center",
                  fontWeight: "700",
                  color: "red",
                  marginTop: "30px",
                }}
              >
                No ongoing exams found.
              </p>
            )}
          </div>
        ) : (
          ""
        )}
        {tabs === "completed" ? (
          <div className="test-cont">
            {loading && <InsideLoader />}
            {!loading && testData && testData.length > 0 ? (
              testData.map((item, index) => (
                <TestCard id={item?.test_id} item={item} key={index} />
              ))
            ) : (
              <p
                style={{
                  textAlign: "center",
                  fontWeight: "700",
                  color: "red",
                  marginTop: "30px",
                }}
              >
                No completed exams found.
              </p>
            )}
          </div>
        ) : (
          ""
        )}
      </div>
    </>
  );
};

export default MyExams;
