import React, { useEffect, useState } from "react";

const TestTimer = ({ testEndTime, submitHandler }) => {
  const [remainingTime, setRemainingTime] = useState("00:00:00:00");
  const [testIsRun, setTestIsRun] = useState(true);
  useEffect(() => {
    const updateRemainingTime = () => {
      const currentDate = new Date().toISOString().split("T")[0];
      let testTime = new Date(`${currentDate}T${testEndTime}`);
      const exactTimeInMilliseconds = testTime.getTime();

      const difference = exactTimeInMilliseconds - Date.now();
      if (difference <= 0) {
        submitHandler();
        setRemainingTime("00:00:00:00");
        setTestIsRun(false);
        return;
      }
      const days = Math.floor(difference / (24 * 60 * 60 * 1000));
      const hours = Math.floor(
        (difference % (24 * 60 * 60 * 1000)) / (60 * 60 * 1000)
      );
      const minutes = Math.floor((difference % (60 * 60 * 1000)) / (60 * 1000));
      const seconds = Math.floor((difference % (60 * 1000)) / 1000);

      setRemainingTime(
        `${days.toString().padStart(2, "0")}:${hours
          .toString()
          .padStart(2, "0")}:${minutes.toString().padStart(2, "0")}:${seconds
          .toString()
          .padStart(2, "0")}`
      );
    };
    const interval = setInterval(() => {
      if (testIsRun) {
        updateRemainingTime();
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [testEndTime, testIsRun]);

  return <div className="timer">{remainingTime}</div>;
};

export default TestTimer;
