import React, { useEffect, useState } from "react";
import PageNavBar from "../../components/NavBar/PageNavBar";
import "./Test.scss";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../components/Loader/Loader";
import RightSideBar from "../../components/NavBar/RightSideBar";
import TestTimer from "../../components/TimerComponet/TestTimer";
import { toast } from "react-toastify";
import { questionSlice, testQuestionSubmit } from "../../slices/testQuestions";
import { useLocation, useNavigate } from "react-router-dom";
const Test = () => {
  const [questionSwitch, setQuestionSwitch] = useState("");
  const testQuestions = useSelector((state) => state.testQuestions);
  const { user } = useSelector((state) => state.user);
  const [currQuesIndex, setCurrQuesIndex] = useState(0);
  const [testQues, setTestQues] = useState(
    testQuestions?.selectedQuesBySubject && testQuestions?.selectedQuesBySubject
  );
  const [isOpen, setIsOpen] = useState(false);
  const [submitDialog, setSubmitDialog] = useState(false);
  const [selectOption, setSelectOption] = useState("");
  const dispatch = useDispatch();
  const location = useLocation();
  const { item } = location.state || {};
  const [alreadyTouched, setAlreadyTouched] = useState({});
  const navigate = useNavigate()

  const toggleDrawer = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    let existingIndex = testQuestions?.attemptedQuestion?.find(
      (item) => item.quesId === testQues[currQuesIndex]?.id
    );
    if (existingIndex) {
      setAlreadyTouched(existingIndex);
      setSelectOption(existingIndex?.ans);
      return;
    }
    setSelectOption("");
  }, [currQuesIndex]);

  useEffect(() => {
    if (testQuestions?.selectedQuesBySubject) {
      setTestQues(testQuestions?.selectedQuesBySubject);
    }
  }, [testQuestions]);

  const saveHandler = () => {
    if (alreadyTouched?.status === "save") {
      toast.error("You have already saved this question");
      return;
    }
    if (!selectOption) {
      toast.warn("Please select an option");
      return;
    }
    dispatch(
      questionSlice.actions.setAttemptedQuestion({
        quesId: testQues[currQuesIndex]?.id,
        status: "save",
        ans: selectOption,
      })
    );
    if (currQuesIndex === testQues.length - 1) {
      setSubmitDialog(true);
      toast.success("Test completed");
      return;
    }

    setCurrQuesIndex(currQuesIndex + 1);
    setSelectOption("");
  };

  const markHandler = () => {
    if (alreadyTouched?.status === "save") {
      toast.error("You have already saved this question");
      return;
    }
    dispatch(
      questionSlice.actions.setAttemptedQuestion({
        quesId: testQues[currQuesIndex]?.id,
        status: "marked",
        ans: selectOption,
      })
    );
    if (currQuesIndex === testQues.length - 1) {
      setSubmitDialog(true);
      toast.success("Test completed");
      return;
    }

    setCurrQuesIndex(currQuesIndex + 1);
    setSelectOption("");
  };

  const directQuesNav = (data) => {
    setCurrQuesIndex(data);
  };

  const submitHandler = async () => {
    let quesId = [];
    let ans = [];
    testQuestions?.attemptedQuestion.map((item) => {
      quesId.push(item.quesId);

      if (item?.ans === "") {
        ans.push("0");
        return;
      }
      ans.push(item.ans);
    });
    ans = testQuestions?.attemptedQuestion.map((item) => {
      if (item?.ans === "") {
        return "0";
      }

      return item?.ans;
    });
    quesId = quesId.toString();
    ans = ans.toString();

    // formData.append("auth_token", auth_token);
    // formData.append("auth_id", auth_id);
    // formData.append("test_id", test_id);
    // formData.append("question_id", test_id);
    // formData.append("ans", test_id);
    // formData.append("total_time", test_id);
    // formData.append("category_id", test_id);
    await dispatch(
      testQuestionSubmit({
        auth_token: user?.data[0]?.auth_token,
        auth_id: user?.data[0]?.auth_id,
        test_id: item?.test_id,
        question_id: quesId,
        ans: ans,
        total_time: 1,
        category_id: 1
      })
    );

    navigate('/')
  };
  return (
    <>
      {testQuestions?.status === "loading" && <Loader />}
      <PageNavBar
        name={
          testQuestions?.testQuestion?.test_detail &&
          testQuestions?.testQuestion?.test_detail[0]?.test_title
        }
        date={
          testQuestions?.testQuestion?.test_detail &&
          testQuestions?.testQuestion?.test_detail[0]?.created.split(" ")[0]
        }
        rightIcon="GiHamburgerMenu"
        toggleDrawer={toggleDrawer}
      />
      <RightSideBar
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        toggleDrawer={toggleDrawer}
        directQuesNav={directQuesNav}
        submitHandler={submitHandler}
      />
      <TestTimer
        testEndTime={item?.unpublish_time}
        submitHandler={submitHandler}
      />
      {submitDialog && (
        <div className="submit-pop-up">
          <div>
            <div className="heading">
              <p>Are you sure you want to submit the test?</p>
            </div>

            <div className="btns">
              <button onClick={submitHandler}>Yes</button>
              <button onClick={() => setSubmitDialog(false)}>No</button>
            </div>
          </div>
        </div>
      )}

      <div className="question-switcher">
        {testQuestions?.selectedSubjects &&
          testQuestions?.selectedSubjects?.map((item, index) => (
            <div
              className={questionSwitch === item ? "active" : ""}
              onClick={() => setQuestionSwitch(item)}
              key={index}
            >
              {item}
            </div>
          ))}
      </div>
      <div className="test-rule-info">
        <p className="green">+ 1</p>
        <p className="red">- 0</p>
        <p className="rule-txt">Single choices is correct</p>
      </div>
      <div className="text-question">
        {testQues && testQues[currQuesIndex]?.question}
      </div>
      <div className="question-options">
        <div
          onClick={() => setSelectOption("ans_a")}
          className={selectOption === "ans_a" ? "active" : ""}
        >
          {testQues && testQues[currQuesIndex]?.ans[0]?.ans_a}
        </div>
        <div
          onClick={() => setSelectOption("ans_b")}
          className={selectOption === "ans_b" ? "active" : ""}
        >
          {testQues && testQues[currQuesIndex]?.ans[0]?.ans_b}
        </div>
        <div
          onClick={() => setSelectOption("ans_c")}
          className={selectOption === "ans_c" ? "active" : ""}
        >
          {testQues && testQues[currQuesIndex]?.ans[0]?.ans_c}
        </div>
        <div
          onClick={() => setSelectOption("ans_d")}
          className={selectOption === "ans_d" ? "active" : ""}
        >
          {testQues && testQues[currQuesIndex]?.ans[0]?.ans_d}
        </div>
      </div>
      <div className="questions-button">
        <button onClick={markHandler}>Mark & Next</button>
        <button
          onClick={() => {
            if (alreadyTouched?.status === "save") {
              toast.error("You have already saved this question");
              return;
            }
            setSelectOption("");
          }}
        >
          Clear
        </button>
        <button className="styled-btn" onClick={saveHandler}>
          Save & Next
        </button>
      </div>
    </>
  );
};

export default Test;
