import React, { useState } from "react";
import { IoIosArrowDropdownCircle } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import { drawerSlice } from "../../slices/drawerSlice";

const MiddleNavBar = () => {
  const [customDrawerCont, setCustomDrawerCont] = useState(false);
  const dispatch = useDispatch();
  const { drawer, drawerValue } = useSelector((state) => state.customDrawer);
  const handleSetDrawerValue = (value) => {
    dispatch(drawerSlice.actions.setvalue(value));
  };
  return (
    <>
      <div
        className="select-cont"
        onClick={() => setCustomDrawerCont(!customDrawerCont)}
      >
        <div>
          <p>{drawerValue?.category_name}</p>
          <IoIosArrowDropdownCircle />
        </div>
      </div>
      {customDrawerCont && (
        <div
          className="custom-drawer"
          onClick={() => setCustomDrawerCont(!customDrawerCont)}
        >
          <div>
            {drawer.map((item, index) => (
              <p
                key={index}
                className={drawerValue?.id === item?.id ? "active" : ""}
                onClick={() => {
                  handleSetDrawerValue(item);
                }}
              >
                {item?.category_name}
              </p>
            ))}
          </div>
        </div>
      )}
    </>
  );
};

export default MiddleNavBar;
