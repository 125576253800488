import React from "react";
import "./insideLoader.scss";

const InsideLoader = () => {
  return (
    <div className="pl">
      <div className="pl__eye"></div>
      <div className="pl__eye"></div>
      <div className="pl__eye"></div>
      <div className="pl__eye"></div>
      <div className="pl__eye"></div>
      <div className="pl__eye"></div>
      <div className="pl__eye"></div>
      <div className="pl__eye"></div>
      <div className="pl__eye"></div>
      <div className="pl__eye"></div>
      <div className="pl__eye"></div>
      <div className="pl__eye"></div>
    </div>
  );
};

export default InsideLoader;
