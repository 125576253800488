import React, { useState } from "react";
import "./NavBar.scss";
import { useSelector } from "react-redux";

const RightSideBar = ({ isOpen, toggleDrawer, directQuesNav, submitHandler }) => {
  const testQuestions = useSelector((state) => state.testQuestions);
  return (
    <div>
      {/* {testQuestions?.attemptedQuestion} */}
      <div className={`drawer ${isOpen ? "open" : ""}`}>
        <p className="data">
          <span>Total Questions: </span>{" "}
          {testQuestions?.selectedQuesBySubject?.length}
        </p>
        {/* <p className="data">
          <span>Total Attemped Questons: </span> 0
        </p> */}

        <div className="questions-cont">
          {testQuestions?.selectedQuesBySubject?.map((item, index) => (
            <div
              onClick={() => directQuesNav(index)}
              key={index}
              className={`${
                testQuestions?.attemptedQuestion[index]?.status === "marked"
                  ? "yellow"
                  : ""
              } ${
                testQuestions?.attemptedQuestion[index]?.status === "save"
                  ? "green"
                  : ""
              }`}
            >
              {index + 1}
            </div>
          ))}
        </div>
        <div className="question-symbol">
          <div className="symbol-cont">
            <div className="green"></div>
            <span>Attempted</span>
          </div>
          <div className="symbol-cont">
            <div className="yellow"></div>
            <span>Marked</span>
          </div>
          <div className="symbol-cont">
            <div className="normal"></div>
            <span>Un-attempted</span>
          </div>
        </div>

        <div className="btn">
          <button onClick={submitHandler}>Submit</button>
        </div>
      </div>
      {isOpen && <div className="backdrop" onClick={toggleDrawer}></div>}
    </div>
  );
};

export default RightSideBar;
