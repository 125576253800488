import React from "react";
import PageNavBar from "../../components/NavBar/PageNavBar";
import MiddleNavBar from "../../components/NavBar/MiddleNavBar";
import { TfiCup } from "react-icons/tfi";
import "./Winner.scss";
const Winner = () => {
  return (
    <div>
      <PageNavBar name="Winner" />
      <MiddleNavBar />
      <div className="contest-win-head">
        <div>
          <TfiCup size="30px" />
        </div>
        <div>
          <p>Contest Winner</p>
          <p>Recent Exam</p>
        </div>
      </div>
      <p
        style={{
          textAlign: "center",
          fontWeight: "700",
          color: "red",
          marginTop: "30px",
        }}
      >
        No winner found.
      </p>
    </div>
  );
};

export default Winner;
