import React from "react";
import PageNavBar from "../../components/NavBar/PageNavBar";
import MiddleNavBar from "../../components/NavBar/MiddleNavBar";

const Courses = () => {
  return (
    <div>
      <PageNavBar name="Courses" />
      <MiddleNavBar />

      <p>
        <p
          style={{
            textAlign: "center",
            fontWeight: "700",
            color: "red",
            marginTop: "30px",
          }}
        >
          No Courses found.
        </p>
      </p>
    </div>
  );
};

export default Courses;
