import { Analytics } from "@vercel/analytics/react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  BrowserRouter,
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import BottomNavBar from "./components/bottomNavBar/BottomNavBar";
import { requestNotificationPermission } from "./firebase";
import AboutUs from "./pages/AboutUs/AboutUs";
import Contact from "./pages/Contact/Contact";
import MyExams from "./pages/MyExams/MyExams";
import Notification from "./pages/Notification/Notification";
import TermAndCondition from "./pages/TermAndCondition/TermAndCondition";
import Test from "./pages/Test/Test";
import Login from "./pages/auth/Login/Login";
import PersonalDetails from "./pages/auth/personlDetails/PersonalDetails";
import Class from "./pages/class/Class";
import Courses from "./pages/courses/Courses";
import Home from "./pages/home/Home";
import PaymentPolicy from "./pages/privacy/PaymentPolicy";
import Privacy from "./pages/privacy/Privacy";
import Support from "./pages/support/Support";
import TestDetail from "./pages/test-detail/TestDetail";
import Language from "./pages/user/Language/Language";
import UserProfile from "./pages/user/UserProfile";
import HelpCenter from "./pages/user/help-center/HelpCenter";
import ReferAndEarn from "./pages/user/referAndEarn/ReferAndEarn";
import Result from "./pages/user/result/Result";
import Wallet from "./pages/user/wallet/Wallet";
import WalletHIstory from "./pages/user/wallet/WalletHIstory";
import Winner from "./pages/winner/Winner";
import { bannerImages } from "./slices/bannerImageSlice";
import { fetchUser } from "./slices/userSlice";

const isAuthenticated = () => {
  return !!localStorage.getItem("user");
};

const PrivateRoute = ({ element }) => {
  return isAuthenticated() ? element : <Navigate to="/login" />;
};
const PublicRoute = ({ element }) => {
  return isAuthenticated() ? <Navigate to="/" /> : element;
};
function Layout({ children }) {
  const location = useLocation(); // Now inside a Router
  const { user } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    fetchFcmToken();
  }, []);

  //fetching fcm token
  const fetchFcmToken = async () => {
    const fcmToken = await requestNotificationPermission();
    localStorage.setItem("fcmToken", fcmToken);
  };

  useEffect(() => {
    fetchUserLocal();
    fetchBanner();
  }, []);

  //fetching banner images
  const fetchBanner = async () => {
    await dispatch(bannerImages());
  };

  const fetchUserLocal = async () => {
    const fetchedUser = JSON.parse(localStorage.getItem("user"));
    if (fetchedUser?.data || user?.isVerified) {
      const response = await dispatch(fetchUser());
      navigate("/");
    }
    await dispatch(fetchUser());
  };
  const hideBottomNavRoutes = [
    "/auth-personaldetails",
    "/profile-detail",
    "/result",
    "/wallet",
    "/help-center",
    "/language",
    "/refer-earn",
    "/test-detail",
    "/test",
    "/login",
    "/support",
    "/notifications",
    "/term-and-condition",
    "/about",
    "/contact",
    "/privacy-and-policy",
    "/payment-and-policy",
  ];

  // Check if the current route matches any of the routes in hideBottomNavRoutes
  const shouldHideBottomNav = hideBottomNavRoutes.some((route) =>
    location.pathname.startsWith(route)
  );

  return (
    <>
      {children}
      {/* Conditionally render BottomNavBar */}
      {!shouldHideBottomNav && <BottomNavBar />}
    </>
  );
}
function App() {
  document.addEventListener("contextmenu", (event) => {
    event.preventDefault();
  });


  // Disable Ctrl+C or Cmd+C
  document.addEventListener("keydown", (event) => {
    if ((event.ctrlKey || event.metaKey) && event.key === "c") {
      event.preventDefault();
      alert("Copying is disabled on this site.");
    }
  });
  return (
    <>
      <BrowserRouter>
        <Layout>
          <Routes>
            {/* auth routes */}
            <Route path="/" element={<PrivateRoute element={<Home />} />} />
            <Route
              path="/login"
              element={<PublicRoute element={<Login />} />}
            />
            <Route
              path="/auth-personaldetails"
              element={<PublicRoute element={<PersonalDetails />} />}
            />

            {/* bottom nav */}
            <Route
              path="/my-exams"
              element={<PrivateRoute element={<MyExams />} />}
            />
            <Route
              path="/winners"
              element={<PrivateRoute element={<Winner />} />}
            />
            <Route
              path="/courses"
              element={<PrivateRoute element={<Courses />} />}
            />
            <Route
              path="/class"
              element={<PrivateRoute element={<Class />} />}
            />

            {/* pages */}
            <Route
              path="/test-detail/:id"
              element={<PrivateRoute element={<TestDetail />} />}
            />
            <Route
              path="/profile-detail"
              element={<PrivateRoute element={<UserProfile />} />}
            />
            <Route
              path="/my-exams"
              element={<PrivateRoute element={<MyExams />} />}
            />
            <Route
              path="/result"
              element={<PrivateRoute element={<Result />} />}
            />
            <Route
              path="/wallet"
              element={<PrivateRoute element={<Wallet />} />}
            />
            <Route
              path="/wallet/history"
              element={<PrivateRoute element={<WalletHIstory />} />}
            />
            <Route
              path="/help-center"
              element={<PrivateRoute element={<HelpCenter />} />}
            />
            <Route
              path="/language"
              element={<PrivateRoute element={<Language />} />}
            />
            <Route
              path="/refer-earn"
              element={<PrivateRoute element={<ReferAndEarn />} />}
            />
            <Route
              path="/test/:id"
              element={<PrivateRoute element={<Test />} />}
            />
            <Route
              path="/support"
              element={<PrivateRoute element={<Support />} />}
            />
            <Route
              path="/notifications"
              element={<PrivateRoute element={<Notification />} />}
            />
            <Route path="/about" element={<AboutUs />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/term-and-condition" element={<TermAndCondition />} />
            <Route path="/privacy-and-policy" element={<Privacy />} />
            <Route path="/payment-and-policy" element={<PaymentPolicy />} />
          </Routes>
        </Layout>
      </BrowserRouter>
      <Analytics />
    </>
  );
}

export default App;
