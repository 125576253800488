import React, { useState } from "react";
import "./TestDetail.scss";
import { FaMedal } from "react-icons/fa";
import { BsCashStack } from "react-icons/bs";
import { FaQuestionCircle } from "react-icons/fa";
import { FaCircleCheck } from "react-icons/fa6";
import AppLogo from "../../assets/app_logo.jpg";
import PageNavBar from "../../components/NavBar/PageNavBar";
import AlertLogo from "../../assets/alert.json";
import Lottie from "lottie-react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
import { toast } from "react-toastify";
import TimerComponet from "../../components/TimerComponet/TimerComponet";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import { setUser, userSlice } from "../../slices/userSlice";
import { api } from "../..";
import { questionSlice, testQuestionFetch } from "../../slices/testQuestions";
import { RxCross2 } from "react-icons/rx";
import Loader from "../../components/Loader/Loader";

const TestDetail = () => {
  const { user } = useSelector((state) => state.user);
  const { drawerValue } = useSelector((state) => state?.customDrawer);
  const testQuestions = useSelector((state) => state?.testQuestions);
  const [paymentCont, setPaymentCont] = useState(false);
  const [paymentMode, setPaymentMode] = useState("");
  const [tabs, setTabs] = useState("cashPrize");
  const [leaderboard, setLeaderboard] = useState([]);
  const [testIsRun, setTestIsRun] = useState(false);
  const [ruleAlert, setRuleAlert] = useState(false);
  const [subjectAlert, setSubjectAlert] = useState(false);
  const transcationIdRandom = uuidv4().slice(0, 6);
  const { id } = useParams();
  const location = useLocation();
  const dispatch = useDispatch();
  const { item } = location.state || {};
  const navigate = useNavigate();
  const [selectSubject, setSelectSubject] = useState([]);
  const [selectedQuestions, setSelectedQuestions] = useState([]);
  const [subjectBtn, setSubjectBtn] = useState(
    testQuestions?.testQuestion?.section_list
  );
  const switcherFun = (data) => {
    setTabs(data);
  };

  useEffect(() => {
    if (testQuestions?.testQuestion?.section_list) {
      setSubjectBtn(testQuestions.testQuestion.section_list);
    }
  }, [testQuestions]);

  //leader board
  useEffect(() => {
    fetchLeaderboard();
  }, []);

  const fetchLeaderboard = async () => {
    try {
      if (item?.result_publish === "true") {
        try {
          const formData = new FormData();
          formData.append("auth_token", user?.data[0]?.auth_token);
          formData.append("auth_id", user?.data[0]?.auth_id);
          formData.append("test_id", item?.test_id);
          formData.append("category_id", drawerValue?.value || 1);

          const response = await api.post(
            "/test_api/Test/test_winer_list",
            formData
          );

          if (response) {
            setLeaderboard(response?.data?.data?.student_detail);
          }
          return;
        } catch (error) {
          console.error("error", error);
          return;
        }
      }
      const formData = new FormData();
      formData.append("auth_token", user?.data[0]?.auth_token);
      formData.append("auth_id", user?.data[0]?.auth_id);
      formData.append("test_id", item?.test_id);
      const response = await api.post(
        "/test_api/Test/purchased_test_student_list",
        formData
      );

      if (response) {
        setLeaderboard(response?.data?.data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://checkout.razorpay.com/v1/checkout.js";
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const startTestHandler = async () => {
    await dispatch(
      testQuestionFetch({
        auth_token: user?.data[0]?.auth_token,
        auth_id: user?.data[0]?.auth_id,
        test_id: item?.test_id,
      })
    );

    setSubjectAlert(true);
  };

  const joinedHandler = () => {
    setPaymentCont(true);
  };

  const cancelPaymentHandler = () => {
    setPaymentCont(false);
  };

  //join test handler
  const paymentHandler = async () => {
    if (paymentMode === "") {
      toast.warning("Please select payment mode");
      return;
    }

    if (paymentMode === "online") {
      const options = {
        key: "rzp_live_1k9vbBh0HX0Ie7",
        amount: parseInt(item?.join_amount) * 100,
        currency: "INR",
        name: "B comptetion",
        order_id: `online_${transcationIdRandom}`,
        description: item?.test_title,
        handler: async (pay_response) => {
          console.log(pay_response?.razorpay_payment_id);

          if (pay_response) {
            const formData = new FormData();
            formData.append("auth_token", user?.data[0]?.auth_token);
            formData.append("auth_id", user?.data[0]?.auth_id);
            formData.append("test_id", item?.test_id);
            formData.append(
              "transaction_number",
              pay_response?.razorpay_payment_id || transcationIdRandom
            );
            formData.append("join_amount", item?.join_amount);
            formData.append("payment_mode", "Online");

            const response = await api.post(
              "/test_api/Test/student_test_subscription",
              formData
            );

            console.log(response);
            if (response?.data) {
              toast.success("Payment successful");
              navigate("/");
            }
          }
        },
        theme: {
          color: "#F37254",
        },
        config: {
          display: {
            blocks: {
              upi: {
                name: "PhonePe / UPI",
                instruments: [
                  {
                    method: "upi",
                    flows: ["collect", "intent"],
                  },
                ],
              },
            },
            sequence: ["block.upi"], // Prioritize UPI/PhonePe
            preferences: {
              show_default_blocks: true, // Show other default options like cards, net banking
            },
          },
        },
        modal: {
          ondismiss: () => {
            console.log("Payment was not completed");
            toast.error("Payment was not successful or was canceled");
          },
        },
      };

      try {
        const razorpay = new window.Razorpay(options);
        razorpay.open();
      } catch (error) {
        console.log("Payment initiation failed", error);
        toast.error("Unable to initiate payment. Please try again.");
      }
    }

    if (paymentMode === "wallet") {
      if (
        parseInt(user?.data[0]?.wallet_amount) < parseInt(item?.join_amount)
      ) {
        toast.warning("Insufficient balance in wallet.");
        return;
      }

      const formData = new FormData();
      formData.append("auth_token", user?.data[0]?.auth_token);
      formData.append("auth_id", user?.data[0]?.auth_id);
      formData.append("test_id", item?.test_id);
      formData.append("transaction_number", transcationIdRandom);
      formData.append("join_amount", item?.join_amount);
      formData.append("payment_mode", "Wallet");

      const response = await api.post(
        "/test_api/Test/student_test_subscription",
        formData
      );
      if (response?.data?.status === 0) {
        toast.warning("Something went wrong please login again");
        return;
      }
      if (response?.data?.status === 1) {
        if (user?.data && user.data[0] && item?.join_amount) {
          const newWalletAmount =
            parseInt(user?.data[0]?.wallet_amount) -
            parseInt(item?.join_amount);
          const newData = user.data.map((entry, index) =>
            index === 0 ? { ...entry, wallet_amount: newWalletAmount } : entry
          );

          dispatch(setUser({ ...user, data: newData }));

          toast.success("Payment successful");
          navigate("/");
        }
      }
    }
  };

  const addSuject = (data) => {
    let cunt = -1;

    if (selectSubject.length >= parseInt(item?.total_attempt_subject)) {
      toast.error("You can select maximum 5 subjects");
      return;
    }
    if (selectSubject.length > 0) {
      cunt = selectSubject?.indexOf(data?.section_name);
    }

    if (cunt > -1) {
      toast.error("subject is already selected");
      return;
    }
    setSelectSubject([...selectSubject, data?.section_name]);
    setSelectedQuestions([...selectedQuestions, ...data?.question_list]);
  };

  const removeSubject = (index) => {
    setSelectSubject(selectSubject.filter((_, i) => i !== index));
  };

  const testStartHandler = () => {
    if (selectSubject.length !== parseInt(item?.total_attempt_subject)) {
      toast.error(`Please select ${item?.total_attempt_subject} subject`);
      return;
    }
    dispatch(
      questionSlice.actions.setSelectedSubjects({ subjects: selectSubject })
    );
    dispatch(
      questionSlice.actions.setSelectedQuesBySubject({
        questions: selectedQuestions,
      })
    );
    navigate(`/test/${id}`, { state: { item: item } });
  };

  return (
    <div className="main-parent-test-detail">
      {testQuestions?.status === "loading" && <Loader />}
      <PageNavBar name={item?.test_title} date={item?.publish_at} />
      {paymentCont && (
        <div className="payment-mode">
          <div>
            <div className="heading">
              <p>Choose a payment mode</p>
            </div>
            <div className="select-container">
              <div className="select">
                <input
                  type="radio"
                  id="wallet"
                  name="payment-mode"
                  onClick={() => setPaymentMode("wallet")}
                />
                <label htmlFor="wallet" name="payment-mode">
                  Wallet
                </label>
              </div>
              <div className="select">
                <input
                  type="radio"
                  id="online"
                  name="payment-mode"
                  onClick={() => setPaymentMode("online")}
                />
                <label htmlFor="online" name="payment-mode">
                  Online
                </label>
              </div>
            </div>

            <div className="btn">
              <button onClick={cancelPaymentHandler}>cancel</button>
              <button onClick={paymentHandler}>pay</button>
            </div>
          </div>
        </div>
      )}

      <div className="test-detail">
        {ruleAlert && (
          <div className="rule-container" onClick={() => setRuleAlert(false)}>
            <div onClick={() => setRuleAlert(true)}>
              <ul className="bold-txt">
                <li>Follow the rules</li>
                <li>No cheating</li>
                <li>Answer all questions</li>
              </ul>

              <Lottie animationData={AlertLogo} loop={true} />

              <ul className="normal-txt">
                <li>Follow the rules</li>
                <li>No cheating</li>
                <li>Answer all questions</li>
              </ul>

              <button onClick={startTestHandler}>Start Test</button>
            </div>
          </div>
        )}

        {subjectAlert && (
          <div className="select-sub-cont">
            <div>
              <div className="heading">
                <p>Select Subject</p>
              </div>
              <p
                style={{
                  textAlign: "center",
                  color: "var(--primary-color)",
                  marginTop: "10px",
                  fontSize: "18px",
                  fontWeight: "bold",
                }}
              >
                Please select carefully {subjectBtn.length - 1} subjects
              </p>
              <div className="subject-btn-cont">
                {subjectBtn &&
                  subjectBtn.map((item, index) => (
                    <button onClick={() => addSuject(item)} key={index}>
                      {item?.section_name}
                    </button>
                  ))}
              </div>
              <div className="selected-subject-cont">
                {selectSubject?.map((item, index) => (
                  <div className="selected-subject" key={index}>
                    {item} <RxCross2 onClick={() => removeSubject(index)} />
                  </div>
                ))}
              </div>

              <div className="btn">
                <button onClick={() => setSubjectAlert(false)}>cancel</button>
                <button onClick={testStartHandler}>Save & Start</button>
              </div>
            </div>
          </div>
        )}

        <div className="prize-pool">
          <div>
            <FaMedal />
            <div>
              <p className="prize-txt">Prize Pool</p>
              <p className="price">₹ {parseInt(item?.prize_pool_amount)}</p>
            </div>
          </div>
          <div>
            {/* new method */}
            {new Intl.DateTimeFormat("en-CA").format(new Date(Date.now())) <
              item.publish_at && (
              <div>
                {(
                  item?.is_subscription === undefined
                    ? true
                    : item?.is_subscription
                ) ? (
                  <TimerComponet
                    item={item}
                    testIsRun={testIsRun}
                    setTestIsRun={setTestIsRun}
                  />
                ) : (
                  <>
                    <p className="start-test" onClick={() => joinedHandler()}>
                      Join Test
                    </p>
                    <TimerComponet
                      item={item}
                      testIsRun={testIsRun}
                      setTestIsRun={setTestIsRun}
                    />
                  </>
                )}
              </div>
            )}
            {new Intl.DateTimeFormat("en-CA").format(new Date(Date.now())) ===
              item?.publish_at &&
            !item?.is_attempt_test &&
            item?.unpublish_time.toString() >
              new Date().toString().split(" ")[4] ? (
              <div>
                {item?.is_subscription &&
                  new Date().toString().split(" ")[4] >
                    item?.publish_time.toString() && (
                    <p
                      className="start-test"
                      onClick={() => setRuleAlert(true)}
                    >
                      Start Test
                    </p>
                  )}

                {!(item?.is_subscription === undefined
                  ? true
                  : item?.is_subscription) &&
                new Date().toString().split(" ")[4] <
                  item?.publish_time.toString() &&
                item?.remain_number_of_slot > 0 ? (
                  <p className="start-test" onClick={() => joinedHandler()}>
                    Join Test
                  </p>
                ) : (
                  <p style={{ color: "red", fontWeight: "600" }}>
                    Better luck next time
                  </p>
                )}

                {new Date().toString().split(" ")[4] < item.unpublish_time && (
                  <TimerComponet
                    item={item}
                    testIsRun={testIsRun}
                    setTestIsRun={setTestIsRun}
                  />
                )}
              </div>
            ) : (
              <div>
                {new Intl.DateTimeFormat("en-CA").format(
                  new Date(Date.now())
                ) >= item.publish_at && (
                  <p style={{ color: "red", fontWeight: "600" }}>
                    Better luck next time
                  </p>
                )}
              </div>
            )}
          </div>
        </div>
        <div className="test-detail-cont">
          <div>
            <p className="data-txt">Total Available Slots</p>
            <p className="txt-red">{item?.remain_number_of_slot} Slots</p>
          </div>
          <div>
            <div>
              <p>Entry Fee</p>
              <div className="cont">
                <BsCashStack /> <span>₹ {item?.join_amount}</span>
              </div>
            </div>
            <div>
              <p>Total Questions</p>
              <div className="cont">
                <FaQuestionCircle />
                <span>{item?.total_attempted_questions}</span>
              </div>
            </div>
            <div>
              <p>Total Marks</p>
              <div className="cont">
                <FaCircleCheck />
                <span>{item?.total_attempted_questions} Marks</span>
              </div>
            </div>
          </div>
          <div>
            <p className="data-txt">Exam Timing</p>
            <p className="txt-red">
              {item?.publish_time} - {item?.unpublish_time}
            </p>
          </div>
        </div>

        <div className="tabs-switch-cont">
          <div onClick={() => switcherFun("cashPrize")}>
            <p className={tabs === "cashPrize" ? "active" : ""}>Reward</p>
            <div className={`bar ${tabs === "cashPrize" ? "baractive" : ""}`} />
          </div>
          <hr />
          <div onClick={() => switcherFun("leaderboard")}>
            <p className={tabs === "leaderboard" ? "active" : ""}>
              Leaderboard
            </p>
            <div
              className={`bar ${tabs === "leaderboard" ? "baractive" : ""}`}
            />
          </div>
          <hr />
          <div onClick={() => switcherFun("examDetails")}>
            <p className={tabs === "examDetails" ? "active" : ""}>
              Exam Details
            </p>
            <div
              className={`bar ${tabs === "examDetails" ? "baractive" : ""}`}
            />
          </div>
          <hr />
          <div onClick={() => switcherFun("pointSystem")}>
            <p className={tabs === "pointSystem" ? "active" : ""}>
              Point System
            </p>
            <div
              className={`bar ${tabs === "pointSystem" ? "baractive" : ""}`}
            />
          </div>
        </div>
        <div className="scroll-cont">
          {tabs === "cashPrize" ? (
            <div className="tabs-detail">
              <div className="rank">
                <p>RANK</p>
                <p>Prize</p>
              </div>
              {item?.cash_price_rank?.map((item, index) => (
                <div key={index}>
                  <p>{item?.cash_prize_rank}</p>
                  <p>₹ {item?.cash_prize_amount}</p>
                </div>
              ))}
            </div>
          ) : (
            ""
          )}
          {tabs === "leaderboard" ? (
            <div className="tabs-detail">
              <div className="rank">
                <p>RANK</p>
                <p>Name</p>
                <p>Marks/Time</p>
              </div>
              {leaderboard &&
                ((item, index) => {
                  const sortedLeaderboard = leaderboard.map((item, index) => ({
                    ...item,
                    rank: index + 1,
                  }));

                  const userIndex = sortedLeaderboard.findIndex(
                    (item) => item?.id === user?.data[0]?.id
                  );

                  let updatedLeaderboard = [...sortedLeaderboard];

                  if (userIndex !== -1) {
                    const [user] = updatedLeaderboard.splice(userIndex, 1);
                    updatedLeaderboard = [{ ...user }, ...updatedLeaderboard];
                  }

                  return updatedLeaderboard.map((item, index) => (
                    <div key={item.id || index}>
                      <p>{item.rank || index + 1}</p>
                      <p>{item.name}</p>
                      <p>0 / 00:00</p>
                    </div>
                  ));
                })()}
            </div>
          ) : (
            ""
          )}
          {tabs === "examDetails" ? (
            <div className="tabs-detail">{item?.exam_detail}</div>
          ) : (
            ""
          )}
          {tabs === "pointSystem" ? (
            <div className="pointSystem-detail">
              <img src={AppLogo} alt="AppLogo" />
              <p>Total Marks : {item?.total_attempted_questions}</p>
              <p>{item?.point_system}</p>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
};

export default TestDetail;
