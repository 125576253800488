import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { api } from "..";

const initialState = {
  testQuestion: [],
  selectedQuesBySubject: [],
  selectedSubjects: [],
  attemptedQuestion: [],
};

//fecth images from apis
export const testQuestionFetch = createAsyncThunk(
  "questions/testQuestionFetch",
  async ({ auth_token, auth_id, test_id }) => {
    try {
      const formData = new FormData();
      formData.append("auth_token", auth_token);
      formData.append("auth_id", auth_id);
      formData.append("test_id", test_id);
      const response = await api.post(
        "/test_api/Test/test_question_list",
        formData
      );
      return response?.data?.data;
    } catch (error) {
      console.log(error);
    }
  }
);

//submit test
export const testQuestionSubmit = createAsyncThunk(
  "questions/testQuestionSubmit",
  async ({ auth_token, auth_id, test_id, question_id, ans, total_time, category_id }) => {
    try {
      const formData = new FormData();
      formData.append("auth_token", auth_token);
      formData.append("auth_id", auth_id);
      formData.append("test_id", test_id);
      formData.append("question_id", question_id);
      formData.append("ans", ans);
      formData.append("total_time", total_time);
      formData.append("category_id", category_id);
      const response = await api.post(
        "/test_api/Test/student_attempt_test",
        formData
      );
      
      return response?.data?.data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const questionSlice = createSlice({
  name: "questions",
  initialState,
  reducers: {
    setSelectedQuesBySubject: (state, action) => {
      const { questions } = action.payload;
      state.selectedQuesBySubject = questions;
    },

    setSelectedSubjects: (state, action) => {
      const { subjects } = action.payload;
      state.selectedSubjects = subjects;
    },

    setAttemptedQuestion: (state, action) => {
      const { quesId, status, ans } = action.payload;
      let existingIndex = state.attemptedQuestion.findIndex(
        (item) => item.quesId === quesId
      );

      if (existingIndex !== -1) {
        state.attemptedQuestion[existingIndex] = { quesId, status, ans };
      } else {
        state.attemptedQuestion.push({ quesId, status, ans });
      }
    },
  },

  extraReducers: (builder) => {
    builder

      //fetch tests
      .addCase(testQuestionFetch.pending, (state) => {
        state.status = "loading";
      })
      .addCase(testQuestionFetch.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.testQuestion = action.payload;
      })
      .addCase(testQuestionFetch.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export default questionSlice.reducer;
