import React, { useEffect, useState } from "react";
import PageNavBar from "../../../components/NavBar/PageNavBar";
import "./Wallet.scss";
import { IoWalletOutline } from "react-icons/io5";
import { BsBank } from "react-icons/bs";
import { PiHandCoinsLight } from "react-icons/pi";
import { IoMdAdd } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { api } from "../../..";
import { useNavigate } from "react-router-dom";
import { loginUser, setUser } from "../../../slices/userSlice";
import { RxCross2 } from "react-icons/rx";

const Wallet = () => {
  const { user } = useSelector((state) => state.user);
  const [totalAmount, setTotalAmount] = useState("0");
  const [walletAmount, setWalletAmount] = useState(
    user?.data && user?.data[0]?.wallet_amount
  );
  const [loading, setLoading] = useState(false);
  const [tabSwitch, setTabSwitch] = useState("addMoney");
  const [transferMethod, setTransferMethod] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [accountNumberConfirm, setAccountNumberConfirm] = useState("");
  const [accountName, setAccountName] = useState("");
  const [bankName, setBankName] = useState("");
  const [ifscCode, setIfscCode] = useState("");
  const [verificationPopup, setVerificationPopup] = useState(false);
  const [sentOtp, setSentOtp] = useState(false);
  const [enterOtp, setEnterOtp] = useState("");
  const [walletUserName, setWalletUserName] = useState("");
  const [walletUserNumber, setWalletUserNumber] = useState("");
  const [otpVerification, setOtpVerification] = useState(false);
  const [resOtp, setResOtp] = useState(null);
  const [transferAmount, setTransferAmount] = useState(0);
  const [bankDetail, setBankDetail] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://checkout.razorpay.com/v1/checkout.js";
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const addCustomMoney = (amount) => {
    let data = parseInt(totalAmount, 10) + amount;
    setTotalAmount(data);
  };

  const addMoneyHandler = async () => {
    try {
      if (totalAmount <= 0) {
        toast.error("Amount should be greater than zero");
        return;
      }
      setLoading(true);

      const options = {
        key: "rzp_live_LndaXSEy0iH3zC",
        amount: parseInt(totalAmount) * 100,
        currency: "INR",
        name: "B comptetion",
        description: "Add Money in wallet...",
        handler: async (pay_response) => {
          console.log(pay_response?.razorpay_payment_id);

          if (pay_response) {
            const formData = new FormData();
            formData.append("auth_token", user?.data[0]?.auth_token);
            formData.append("auth_id", user?.data[0]?.auth_id);
            formData.append("wallet_amount", totalAmount);
            formData.append(
              "transaction_number",
              pay_response?.razorpay_payment_id
            );

            const response = await api.post(
              "/test_api/Student/add_wallet_amount",
              formData
            );

            if (response?.data) {
              const newWalletAmount =
                parseInt(user?.data[0]?.wallet_amount) + parseInt(totalAmount);
              const newData = user.data.map((entry, index) =>
                index === 0
                  ? { ...entry, wallet_amount: newWalletAmount }
                  : entry
              );

              dispatch(setUser({ ...user, data: newData }));
              toast.success("Payment successful");
              navigate("/");
            }
          }
        },
        theme: {
          color: "#F37254",
        },
        modal: {
          ondismiss: () => {
            console.log("Payment was not completed");
            toast.error("Payment was not successful or was canceled");
          },
        },
      };

      try {
        const razorpay = new window.Razorpay(options);
        razorpay.open();
      } catch (error) {
        console.log("Payment initiation failed", error);
        toast.error("Unable to initiate payment. Please try again.");
      }
    } catch (error) {
      toast.error("Add Money Failed");
      console.log(error);
      setLoading(false);
    }
  };

  const bankTransferHandler = async () => {
    try {
      if (
        accountNumber === "" ||
        accountNumberConfirm === "" ||
        accountName === "" ||
        bankName === "" ||
        ifscCode === ""
      ) {
        toast.error("All fields are required");
        return;
      }
      if (accountNumber !== accountNumberConfirm) {
        toast.error("Account number and confirm account number are not same");
        return;
      }

      setVerificationPopup(true);
      setTransferMethod("bank");
      const data = {
        accountNumber,
        bankName,
        accountName,
        ifscCode,
      };
      setBankDetail(JSON.stringify(data));
    } catch (error) {
      toast.error("Bank Transfer Failed");
      console.log(error);
    }
  };

  const setOtpHandler = async () => {
    setSentOtp(true);
    const response = await dispatch(
      loginUser({
        mobileNo: user?.data && user?.data[0]?.mobile_number,
        deviceId: user?.data && user?.data[0]?.device_id,
      })
    ).unwrap();
    setResOtp(response?.otp);
    console.log(response);
  };

  const verifyHandler = async () => {
    if (parseInt(enterOtp) !== resOtp) {
      toast.error("Incorrect OTP");
      return;
    }
    if (transferAmount < 200) {
      toast.error("You are not able to withdraw below 200 INR");
      return;
    }

    if (
      JSON.parse(user?.data && user?.data[0]?.wallet_amount) < transferAmount
    ) {
      toast.error("You do not have sufficient balance in your wallet");
      return;
    }

    //bank transfer request
    try {
      if (transferMethod === "bank") {
        const formData = new FormData();

        formData.append("auth_token", user?.data[0]?.auth_token);
        formData.append("auth_id", user?.data[0]?.auth_id);
        formData.append("amount", transferAmount);
        formData.append("bank_detail", bankDetail);
        formData.append("date_of_birth", user?.data[0]?.date_of_birth);
        formData.append("mobile_number", "");

        const { data } = await api.post(
          "/test_api/Student/student_transfer_money_request",
          formData
        );

        if (data && data?.status === 1) {
          toast.success("Transfer request sent successfully");
          setVerificationPopup(false);
          navigate("/");

          return;
        }
        if (data && data?.status === 0) {
          toast.error("Transfer Failed");
          setVerificationPopup(false);
          navigate("/");
          return;
        }
      }
    } catch (error) {
      console.log(error);
      toast.error("Transfer Failed");
      setVerificationPopup(false);
    }

    //wallet transfer request
    try {
      if (transferMethod === "wallet") {
        const formData = new FormData();

        formData.append("auth_token", user?.data[0]?.auth_token);
        formData.append("auth_id", user?.data[0]?.auth_id);
        formData.append("amount", transferAmount);
        formData.append("bank_detail", "");
        formData.append("date_of_birth", user?.data[0]?.date_of_birth);
        formData.append("mobile_number", walletUserNumber);

        const { data } = await api.post(
          "/test_api/Student/student_transfer_money_request",
          formData
        );

        if (data && data?.status === 1) {
          toast.success("Transfer request sent successfully");
          setVerificationPopup(false);
          navigate("/");

          return;
        }
        if (data && data?.status === 0) {
          toast.error("Transfer Failed");
          setVerificationPopup(false);
          navigate("/");
          return;
        }
      }
    } catch (error) {
      console.log(error);
      toast.error("Transfer Failed");
      setVerificationPopup(false);
    }
  };

  const walletTransferHandler = () => {
    try {
      if (walletUserName === "" || walletUserNumber === "") {
        toast.error("All fields are required");
        return;
      }
      setVerificationPopup(true);
      setTransferMethod("wallet");
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <>
      <PageNavBar name="Wallet" rightIcon="History" />
      {verificationPopup && (
        <div className="verificationPopup">
          <div>
            <div className="heading">
              <p>Verify Details</p>
              <RxCross2 onClick={() => setVerificationPopup(false)} />
            </div>
            <input
              type="tel"
              readOnly
              value={user?.data && user?.data[0]?.mobile_number}
            />
            {!sentOtp ? (
              <p onClick={setOtpHandler} style={{ color: "red" }}>
                send otp
              </p>
            ) : (
              <input
                type="tel"
                className="inp-otp"
                placeholder="0 0 0 0"
                maxLength={4}
                value={enterOtp}
                onChange={(e) => setEnterOtp(e.target.value)}
              />
            )}
            <input
              type="number"
              placeholder="Please Enter Transfer Amount"
              value={transferAmount}
              onChange={(e) => setTransferAmount(e.target.value)}
            />
            <button onClick={verifyHandler}>Verify</button>
          </div>
        </div>
      )}

      <div className="wallet-cont">
        <div className="balnace">
          <p>Your Wallet Balance</p>
          <p>{walletAmount}</p>
        </div>
        <div className="balance-method">
          <div onClick={() => setTabSwitch("addMoney")}>
            <IoWalletOutline />
            <p>Add Money</p>
          </div>
          <hr />
          <div onClick={() => setTabSwitch("bankTransfer")}>
            <BsBank />
            <p>Bank Transfer</p>
          </div>
          <hr />
          <div onClick={() => setTabSwitch("walletTransfer")}>
            <PiHandCoinsLight />
            <p>Wallet Transfer</p>
          </div>
        </div>
        {tabSwitch === "addMoney" && (
          <div className="add-money">
            <p>Add Amount</p>
            <input
              type="number"
              value={totalAmount}
              onChange={(e) => setTotalAmount(e.target.value)}
            />
            <div>
              <div onClick={() => addCustomMoney(100)}>
                <IoMdAdd /> 100
              </div>
              <div onClick={() => addCustomMoney(500)}>
                <IoMdAdd /> 500
              </div>
              <div onClick={() => addCustomMoney(1000)}>
                <IoMdAdd /> 1000
              </div>
            </div>
            <button onClick={addMoneyHandler}>Add Amount</button>
          </div>
        )}
        {tabSwitch === "bankTransfer" && (
          <div className="bankTransfer">
            <p>Transfer Balance to bank account</p>
            <input
              type="text"
              placeholder="Please add your bank account name"
              value={accountName}
              onChange={(e) => setAccountName(e.target.value)}
            />
            <input
              type="text"
              placeholder="Please add your bank name"
              value={bankName}
              onChange={(e) => setBankName(e.target.value)}
            />
            <input
              type="text"
              placeholder="Please add your bank ifsc code"
              value={ifscCode}
              onChange={(e) => setIfscCode(e.target.value)}
            />
            <input
              type="text"
              placeholder="Please add your bank account number"
              value={accountNumber}
              onChange={(e) => setAccountNumber(e.target.value)}
            />
            <input
              type="text"
              placeholder="Please confirm your account number"
              value={accountNumberConfirm}
              onChange={(e) => setAccountNumberConfirm(e.target.value)}
            />

            <p className="chargers">Withdrawal Charges 10%</p>

            <button onClick={bankTransferHandler}>PROCEED</button>
          </div>
        )}
        {tabSwitch === "walletTransfer" && (
          <div className=" walletTransfer">
            <p>Transfer Balance in Wallet</p>
            <input
              type="text"
              placeholder="Please add your user name"
              value={walletUserName}
              onChange={(e) => setWalletUserName(e.target.value)}
            />
            <input
              type="tel"
              placeholder="Please add your user number"
              value={walletUserNumber}
              onChange={(e) => setWalletUserNumber(e.target.value)}
            />

            <button onClick={walletTransferHandler}>PROCEED</button>
          </div>
        )}
      </div>
    </>
  );
};

export default Wallet;
