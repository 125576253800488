import React from "react";
import "./PopUpNotification.scss";
import { GrStatusGood } from "react-icons/gr";

const PopUpNoti = () => {
  return (
    <div className="popUpNoti">
        <GrStatusGood />
      <p>Otp Sent on Mobile and Email</p>
    </div>
  );
};

export default PopUpNoti;
