import React from "react";
import PageNavBar from "../../components/NavBar/PageNavBar";
import "./support.scss";

const Support = () => {
  return (
    <div className="support-cont">
      <PageNavBar name="Support" />
      <div className="btn">
        <button>CHAT SUPPORT</button>
      </div>
      <div className="freq-ques">
        <p className="heading">Frequently Asked Questions</p>

        <details>
          <summary class="no-icon">What types of courses are offered?</summary>
          <p>Here is the content!</p>
        </details>

        <details>
          <summary class="no-icon">How do I enroll in a course?</summary>
          <p>Here is the content that is open by default!</p>
        </details>
        <details>
          <summary class="no-icon">
            Can i access the coures on my mobile device?
          </summary>
          <p>Here is the content that is open by default!</p>
        </details>
      </div>
    </div>
  );
};

export default Support;
