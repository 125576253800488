import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "./index.css";
import { Provider } from "react-redux";
import store from "./store";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
const baseUrl = false
  ? "https://bonlinemarket.com"
  : "http://localhost:3000";

export const api = axios.create({
  baseURL: baseUrl,
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <App />
    <ToastContainer autoClose={3000} draggable={true} />
  </Provider>
);
