import React, { useEffect } from "react";
import "./Notification.scss";
import PageNavBar from "../../components/NavBar/PageNavBar";
import { GoBell } from "react-icons/go";
import { useDispatch, useSelector } from "react-redux";
import { fetchNotifications } from "../../slices/notificationSlice";
import InsideLoader from "../../components/InsideLoader/InsideLoader";

const Notification = () => {
  const { user } = useSelector((state) => state?.user);
  const notification = useSelector((state) => state?.notification);
  const dispatch = useDispatch();

  const fetchNotificationsData = async () => {
    await dispatch(
      fetchNotifications({
        auth_token: user?.data && user?.data[0]?.auth_token,
        auth_id: user?.data && user?.data[0]?.auth_id,
      })
    );
  };

  useEffect(() => {
    fetchNotificationsData();
  }, []);
  return (
    <div>
      <PageNavBar name="Notification" />
      {notification?.status === "loading" ? (
        <InsideLoader />
      ) : (
        notification?.notifications?.data &&
        notification?.notifications?.data?.map((item, index) => (
          <div className="notification-card" key={index}>
            <div>
              <GoBell />
            </div>
            <div>
              <div>
                <p>{item?.title}</p>
                <p>{item?.created}</p>
              </div>
              <div>
                <p>
                  {item?.notification_detail?.slice(0, 30)}{" "}
                  {item?.notification_detail?.length > 28 ? "..." : ""}
                </p>
              </div>
            </div>
          </div>
        ))
      )}
    </div>
  );
};

export default Notification;
