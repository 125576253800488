import React, { useEffect, useState } from "react";
import NavBar from "../../components/NavBar/NavBar";
import "./Home.scss";
import ImageCarousel from "../../components/ImageCarousel/ImageCarousel";
import TestCard from "../../components/TestCard/TestCard";
import { TbCheckupList } from "react-icons/tb";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { fetchUser } from "../../slices/userSlice";
import { homeTests } from "../../slices/testList";
import InsideLoader from "../../components/InsideLoader/InsideLoader";
import axios from "axios";
import { drawerCategory } from "../../slices/drawerSlice";

const Home = () => {
  const { user } = useSelector((state) => state.user);
  const tests = useSelector((state) => state.tests);
  const { drawerValue } = useSelector((state) => state.customDrawer);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    // fetchTest();
    fetchCategory();
  }, []);

  useEffect(() => {
    fetchTest();
  }, [drawerValue]);

  const fetchTest = async () => {
    if (user?.data) {
      const response = await dispatch(
        homeTests({
          auth_token: user?.data && user?.data[0]?.auth_token,
          auth_id: user?.data && user?.data[0]?.auth_id,
          drawerValue: drawerValue?.id || 1,
        })
      );
    }
  };

  const fetchCategory = async () => {
    const response = await dispatch(drawerCategory());
  };
  return (
    <div className="homepage">
      <NavBar />
      <ImageCarousel />
      <div className="online-exams">
        <TbCheckupList />
        <p>Online Exams</p>
      </div>
      {tests?.status === "loading" ? (
        <InsideLoader />
      ) : (
        <div className="test-cont">
          {tests &&
            tests?.tests?.map((item, index) => (
              <TestCard id={item?.test_id} item={item} key={index} />
            ))}
          {tests && tests?.tests === null && (
            <p
              style={{
                textAlign: "center",
                marginTop: "30px",
                fontSize: "18px",
              }}
            >
              No Tests Found.
            </p>
          )}
        </div>
      )}
    </div>
  );
};

export default Home;
