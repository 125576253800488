import React, { useState, useEffect } from "react";
import AppLogo from "../../assets/app_logo.jpg";
import "./UserProfile.scss";
import PageNavBar from "../../components/NavBar/PageNavBar";
import { useSelector } from "react-redux";
import { api } from "../..";
import Loader from "../../components/Loader/Loader";

const UserProfile = () => {
  const { user } = useSelector((state) => state.user);
  const [inputType, setInputType] = useState("text");
  const [name, setName] = useState(user?.data && user?.data[0]?.name);
  const [email, setEmail] = useState(user?.data && user?.data[0]?.email);
  const [collegeName, setCollegeName] = useState(
    user?.data && user?.data[0]?.school_name
  );
  const [dateOfBirth, setDateOfBirth] = useState(
    user?.data && user?.data[0]?.date_of_birth
  );
  const [gender, setGender] = useState(user?.data && user?.data[0]?.gender);
  const [address, setAddress] = useState(user?.data && user?.data[0]?.address);
  const [pinCode, setPinCode] = useState(user?.data && user?.data[0]?.pin_code);
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState({
    id: "",
    country: "",
  });
  const [selectedState, setSelectedState] = useState({
    id: user?.data && user?.data[0]?.state_id,
    state: user?.data && user?.data[0]?.state_name,
  });
  const [selectedCity, setSelectedCity] = useState({
    id: user?.data && user?.data[0]?.city_id,
    city: user?.data && user?.data[0]?.city_name,
  });
  useEffect(() => {
    // Fetch all countries when the component loads
    fetchCountriesData();
  }, []);

  const fetchCountriesData = async () => {
    const { data } = await api.post("/test_api/Api/country_list");

    if (data) {
      setCountries(data && data?.data);
    }
  };

  const handleCountryChange = async (event) => {
    const selectedCountryData = JSON.parse(event.target.value);
    setSelectedCountry(selectedCountryData);

    const formData = new FormData();
    setLoading(true);
    formData.append("country_id", selectedCountryData.id || 1); // Use selectedCountryData directly
    try {
      const { data } = await api.post("/test_api/Api/state_list", formData);
      if (data) {
        console.log(data);
        setStates(data?.data);
      }

      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const handleStateChange = async (event) => {
    const selectedStateData = JSON.parse(event.target.value);
    setSelectedState(selectedStateData);

    const formData = new FormData();
    formData.append("state_id", selectedStateData.id); // Use selectedCountryData directly

    try {
      const { data } = await api.post("/test_api/Api/city_list", formData);

      if (data) {
        setCities(data?.data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleCityChange = async (event) => {
    const selectedCityData = JSON.parse(event.target.value);
    setSelectedCity(selectedCityData);
  };
  return (
    <>
      <PageNavBar name="Profile Details" />
      {loading && <Loader />}
      <div className="container">
        <div className="logincard">
          <div className="applogo">
            <img src={AppLogo} alt="AppLogo" />
          </div>
          <div className="basic-info">
            <p className="title">Basic Info</p>
            <div className="details">
              <input
                type="text"
                placeholder="Please enter your name"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />

              <input
                type="email"
                placeholder="Please enter your email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <input
                type="text"
                placeholder="Please enter your school/college name"
                value={collegeName}
                onChange={(e) => setCollegeName(e.target.value)}
              />
              <input
                type={inputType}
                placeholder="dd-mm-yy"
                onFocus={() => setInputType("date")}
                onBlur={() => setInputType("text")}
                value={dateOfBirth}
                onChange={(e) => setDateOfBirth(e.target.value)}
              />
              <div className="gender-cont">
                <p>Please select your gender</p>
                <input
                  type="radio"
                  name="gender"
                  id="male"
                  checked={gender === "Male"}
                  onChange={() => setGender("Male")}
                />
                <label htmlFor="male">Male</label>
                <input
                  type="radio"
                  name="gender"
                  id="female"
                  checked={gender === "Female"}
                  onChange={() => setGender("Female")}
                />
                <label htmlFor="female">female</label>
              </div>
            </div>
          </div>

          <div className="basic-info">
            <p className="title">Personal Info</p>
            <div className="details">
              <input
                type="text"
                placeholder="Please enter your address"
                value={address}
                onChange={(e) => setAddress(e.target.value)}
              />

              <input
                type="tel"
                placeholder="Please enter your pincode"
                maxLength={5}
                value={pinCode}
                onChange={(e) => setPinCode(e.target.value)}
              />
              <select
                value={JSON.stringify(selectedCountry.country)}
                onChange={(e) => handleCountryChange(e)}
              >
                {countries &&
                  countries.map((country, index) => (
                    <option
                      key={index}
                      value={JSON.stringify({
                        id: country.id,
                        country_name: country.country_name,
                      })}
                    >
                      {country.country_name}
                    </option>
                  ))}
              </select>
              <select
                value={selectedState?.state}
                onChange={(e) => handleStateChange(e)}
              >
                {states?.length > 0 ? (
                  states?.map((state, index) => (
                    <option
                      key={index}
                      value={JSON.stringify({
                        id: state.id,
                        country_name: state.state_name,
                      })}
                    >
                      {state.state_name}
                    </option>
                  ))
                ) : (
                  <option>{selectedState?.state}</option>
                )}
              </select>
              <select
                value={JSON.stringify(selectedCity.city)}
                onChange={(e) => handleCityChange(e)}
              >
                {cities?.length > 0 ? (
                  cities?.map((city, index) => (
                    <option
                      key={index}
                      value={JSON.stringify({
                        id: city.id,
                        country_name: city.city_name,
                      })}
                    >
                      {city.city_name}
                    </option>
                  ))
                ) : (
                  <option>{selectedCity.city}</option>
                )}
              </select>

              <button className="btn-register">Save</button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserProfile;
