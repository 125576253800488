import React, { useEffect, useState } from "react";
import PageNavBar from "../../../components/NavBar/PageNavBar";
import { useSelector } from "react-redux";
import "./Wallet.scss";
import { api } from "../../..";
import InsideLoader from "../../../components/InsideLoader/InsideLoader";

const WalletHIstory = () => {
  const { user } = useSelector((state) => state.user);
  const [walletTransaction, setWalletTransaction] = useState();
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    fetchWalletTransaction();
  }, []);

  const fetchWalletTransaction = async () => {
    try {
      const formData = new FormData();
      setLoading(true);
      formData.append("auth_token", user?.data[0]?.auth_token);
      formData.append("auth_id", user?.data[0]?.auth_id);
      const { data } = await api.post(
        "/test_api/Student/student_wallet_history",
        formData
      );

      if (data) {
        console.log(data);
        setWalletTransaction(data?.data);
      }

      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };
  return (
    <>
      <PageNavBar name="wallet History" />
      {loading && <InsideLoader />}
      {walletTransaction &&
        walletTransaction?.map((item, index) => (
          <div className="wallet-noti-cont" key={index}>
            <div>
              <p>{item?.name}</p>
              <p>Transcation: {item?.transaction_number}</p>
              <p>{item?.created} </p>
            </div>
            <div>
              <p>{item?.mobile_number}</p>
              <p className="rs">₹ {item?.wallet_amount} </p>
              <p className="btn">{item?.transaction_type}</p>
            </div>
          </div>
        ))}
    </>
  );
};

export default WalletHIstory;
