import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

// Firebase project configuration
const firebaseConfig = {
  apiKey: "AIzaSyDoOn5JOhxPscApKQ_iJBCj-56Fi7eSHCw",
  authDomain: "instagram-770e1.firebaseapp.com",
  projectId: "instagram-770e1",
  storageBucket: "instagram-770e1.appspot.com",
  messagingSenderId: "90302667726",
  appId: "1:90302667726:web:15ccd4bc74a7b502ef699f",
  measurementId: "G-FXQ3KF461D",
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);

// Initialize Firebase Cloud Messaging and get a reference to the service
export const messaging = getMessaging(app);

// Request permission to show notifications and get the FCM token
export const requestNotificationPermission = async () => {
  try {
    const permission = await Notification.requestPermission();
    if (permission === "granted") {
      // Get the registration token for FCM
      const fcmToken = await getToken(messaging, {
        vapidKey:
          "BJ3RZXoviCMAnq4uZalukbqQ8Qu1egQbE4LHX-zXbnMxgDuw7TH52Kve4kIF1PlNWlwUVFckmWTga8B-4mYQYrM",
      });

      if (fcmToken) {
        return fcmToken;
        // Send this token to your server or use it as needed
      } else {
        console.log(
          "No registration token available. Request permission to generate one."
        );
      }
    } else {
      console.log("Notification permission denied.");
    }
  } catch (error) {
    console.error("Error getting notification permission:", error);
  }
};

// Handle incoming messages when the app is in the foreground
onMessage(messaging, (payload) => {
  console.log("Message received. ", payload);
  // Customize notification handling here
});
