import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { api } from "../index";
const initialState = {
  images: {},
};

//fecth images from apis
export const bannerImages = createAsyncThunk(
  "images/bannerImages",
  async () => {
    const formData = new FormData();
    formData.append("category_id", 1);
    const response = await api.post(
      "/test_api/Api/banner_list",
      formData
    );
    return response.data;
  }
);

export const bannerImageSlice = createSlice({
  name: "images",
  initialState,
  reducers: {
    setvalue: (state, action) => {
      state.images = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder

      //fetch images
      .addCase(bannerImages.pending, (state) => {
        state.status = "loading";
      })
      .addCase(bannerImages.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.images = action.payload;
      })
      .addCase(bannerImages.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export default bannerImageSlice.reducer;
