import React from "react";
import PageNavBar from "../../../components/NavBar/PageNavBar";
import { MdOutlineMailOutline } from "react-icons/md";
import { IoCallOutline } from "react-icons/io5";
import { FaWhatsapp } from "react-icons/fa";
import "./helpCenter.scss";

const HelpCenter = () => {
  const whatsappHandler = () => {
    const message = encodeURIComponent(`Help me`);
    const link = `https://wa.me/+91 9931086338?text=${message}`;
    window.open(link, "_blank");
  };

  const mailHandler = () => {
    const recipient = "helpbcompetition@gmail.com";
    const subject = "Help";
    const body = "Hello,\n\nI would like to ask about...";
    const mailtoLink = `mailto:${recipient}?subject=${encodeURIComponent(
      subject
    )}&body=${encodeURIComponent(body)}`;
    window.location.href = mailtoLink;
  };

  const phoneHandler = () => {
    const number = "+91 9931086338";
    window.location.href = `tel:${number}`;
  };
  return (
    <div>
      <PageNavBar name="Help Center" />

      <div className="help-center-cont">
        <p className="txt-color">We are here to help.</p>
        <p onClick={mailHandler}>
          <MdOutlineMailOutline /> helpbcompetition@gmail.com
        </p>
        <p onClick={phoneHandler}>
          <IoCallOutline />
          +91 9931086338
        </p>
        <p onClick={whatsappHandler}>
          <FaWhatsapp />B competition
        </p>
      </div>
    </div>
  );
};

export default HelpCenter;
